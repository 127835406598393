.comment {
  $base-border-radius: 3px !default;
  $base-spacing: 1.5em !default;
  $action-color: #477DCA !default;
  $dark-gray: #333 !default;
  $base-font-color: $dark-gray !default;
  $comment-gutter: 1.4em;
  $comment-color: $base-font-color;
  $comment-background: lighten($action-color, 15%);
  $comment-detail-color: transparentize($comment-color, 0.5);
  $comment-image-vert-alignment: top;

  border-bottom: 1px solid transparentize($comment-color, 0.9);
  display: table;
  margin-bottom: $base-spacing;
  padding-bottom: 1em;
  width: 100%;

  .comment-image,
  .comment-content {
    display: table-cell;
    vertical-align: $comment-image-vert-alignment; }

  .comment-image {
    @include transition(all 0.2s ease-in-out);
    padding-right: $comment-gutter;
    > img {
      background: $comment-background;
      border-radius: $base-border-radius;
      display: block;
      max-width: none;
      width: 100px;
      height: 100px;
      object-fit: cover; } }

  .comment-content {
    width: 100%;
    h1 {
      font-size: 1em;
      margin: 0 0 0.5em 0; }
    p {
      line-height: 1.5em;
      margin-bottom: 0.5em; } }

  &:hover {
    .comment-image {
      opacity: 0.7; } } }
