.bullets {
  $base-line-height: 1.5em !default;
  $action-color: #477DCA !default;
  $dark-gray: #333 !default;
  $base-font-color: $dark-gray !default;
  $icon-bullet-size: 3em;

  @include display(flex);
  @include flex-wrap(wrap);
  margin-bottom: $base-line-height;
  margin: 1em;
  overflow: none;
  padding: 0;

  .bullet {
    @include flex-basis(20em);
    @include flex-grow(1); }


  a {
    color: $base-font-color; }

  .bullet-icon {
    //background: $action-color
    //border-radius: 50%
    float: left;
    height: $icon-bullet-size;
    padding: 0;
    width: $icon-bullet-size; }


  .bullet-content {
    margin-left: $icon-bullet-size * 1.4;
    margin-bottom: 2em; }


  h2 {
    border-bottom: 1px solid transparentize($base-font-color, 0.8);
    display: inline-block;
    font-size: $icon-bullet-size / 2.5;
    margin: 0 0 ($icon-bullet-size / 6) 0;
    padding-top: $icon-bullet-size / 7; }


  li {
    list-style: none; }


  p {
    color: $base-font-color;
    line-height: $base-line-height; }


  img {
    max-width: 100%; } }
