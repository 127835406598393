header.navigation {
  $base-border-color: gainsboro !default;
  $base-border-radius: 3px !default;
  $action-color: #477DCA !default;
  $dark-gray: #333 !default;
  $large-screen: 53.75em !default;
  $navigation-background: $white;
  $navigation-padding: 1em;
  $navigation-color: transparentize(black, 0.3);
  $navigation-color-hover: black;
  $navigation-height: 60px;
  $navigation-nav-button-background: $action-color;
  $navigation-nav-button-background-hover: lighten($navigation-background, 10%);
  $navigation-nav-button-border: 1px solid lighten($navigation-nav-button-background, 20%);
  $navigation-search-background: lighten($navigation-background, 5);
  $navigation-search-border: 1px solid darken($navigation-background, 5);
  $navigation-active-link-color: transparentize($red, 0.5);
  $navigation-submenu-padding: 1em;
  $navigation-submenu-width: 12em;

  background-color: $navigation-background;
  border-bottom: 1px solid darken($navigation-background, 10);
  min-height: $navigation-height;
  width: 100%;
  z-index: 999;

  .navigation-wrapper {
    @include clearfix;
    position: relative;
    z-index: 9999; }
  .logo {
    float: left;
    max-height: $navigation-height;
    padding-left: $navigation-padding;
    padding-right: 2em;
    img {
      max-height: $navigation-height;
      padding: 0.1em 0; } }

  // Mobile view
  .navigation-menu-button {
    color: $navigation-color;
    display: block;
    float: right;
    line-height: $navigation-height;
    margin: 0;
    padding-right: 1em;
    text-decoration: none;
    text-transform: uppercase;
    @include media ($large-screen) {
      display: none; }
    &:focus,
    &:hover {
      color: $navigation-color-hover; } }

  // Nav menu
  nav {
    float: none;
    min-height: $navigation-height;
    z-index: 9999999;
    @include media ($large-screen) {
      float: right; } }
  ul.navigation-menu {
    clear: both;
    display: none;
    margin: 0 auto;
    overflow: visible;
    padding: 0;
    width: 100%;
    z-index: 9999;
    &.show {
      display: block; }
    @include media ($large-screen) {
      display: inline;
      margin: 0;
      padding: 0; } }

  // The nav items
  ul li.nav-link {
    background: $navigation-background;
    display: block;
    line-height: $navigation-height;
    overflow: hidden;
    padding-right: 0.4em;
    padding-left: 0.4em;
    text-align: right;
    width: 100%;
    z-index: 9999;
    @include media ($large-screen) {
      background: transparent;
      display: inline;
      line-height: $navigation-height;
      text-decoration: none;
      width: auto; }
    a {
      color: $navigation-color;
      display: inline-block;
      text-decoration: none;
      @include media ($large-screen) {
        padding-right: 0.5em;
        padding-left: 0.5em; }
      &:focus,
      &:hover {
        color: $navigation-color-hover; } } }
  .active-nav-item a {
    border-bottom: 1px solid $navigation-active-link-color;
    padding-bottom: 3px; }

  // // Sub menus
  // li.more.nav-link
  //   padding-right: 0
  //   @include media($large-screen)
  //     padding-right: $navigation-submenu-padding
  //   > ul > li:first-child a
  //     padding-top: 1em
  //   a
  //     margin-right: $navigation-submenu-padding
  //   > a
  //     padding-right: 0.6em
  //   > a::after
  //     @include position(absolute, auto -0.4em auto auto)
  //     color: $navigation-color
  //     content: "\25BE"
  // li.more
  //   overflow: visible
  //   padding-right: 0
  //   a
  //     padding-right: 0.8em
  //   > a
  //     padding-right: 1.6em
  //     position: relative
  //     @include media($large-screen)
  //       margin-right: $navigation-submenu-padding
  //     &::after
  //       content: "›"
  //       font-size: 1.2em
  //       position: absolute
  //       right: $navigation-submenu-padding / 2
  //   &:focus > .submenu,
  //   &:hover > .submenu
  //     display: block
  //     padding-right: 0.8em
  //     position: relative
  // ul.submenu
  //   display: none
  //   padding-left: 0
  //   @include media($large-screen)
  //     left: -$navigation-submenu-padding
  //     position: absolute
  //     top: 1.5em
  //   .submenu.fly-out-right
  //     @include media($large-screen)
  //       left: $navigation-submenu-width - 0.2em
  //       top: 0
  //   .submenu.fly-out-left
  //     @include media($large-screen)
  //       left: -$navigation-submenu-width + 0.2em
  //       top: 0
  //   .submenu
  //     @include media($large-screen)
  //       left: $navigation-submenu-width - 0.2em
  //       top: 0

  //   li
  //     display: block
  //     padding-right: 0
  //     @include media($large-screen)
  //       line-height: $navigation-height / 1.3
  //       &:first-child > a
  //         border-top-left-radius: $base-border-radius
  //         border-top-right-radius: $base-border-radius
  //       &:last-child > a
  //         border-bottom-left-radius: $base-border-radius
  //         border-bottom-right-radius: $base-border-radius
  //         padding-bottom: 0.7em
  //     a
  //       background-color: darken($navigation-background, 3%)
  //       display: inline-block
  //       text-align: right
  //       width: 100%
  //       @include media($large-screen)
  //         background-color: $navigation-background
  //         padding-left: $navigation-submenu-padding
  //         text-align: left
 }  //         width: $navigation-submenu-width
