$gray: rgba(0,0,0,0.2);
$red: #D40000;
$white: white;

$medium-screen: 840px !default;
$large-screen: 1200px !default;

@import "bourbon/bourbon";
@import "neat/neat";
@import "flat";
@import "cards";
@import "bullets";
@import "comments";
@import "tabs";
@import "navigation";

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

body {
  margin: 0;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 300;
  height: 100vh;
  display: flex;
  flex-direction: column; }

.page-body {
  flex: 1;
  overflow-y: auto; }

.page-footer {
  flex: 0 0 auto; }

.wrap:before, .wrap:after {
  content: "";
  display: table; }
.wrap:after {
  clear: both; }
.wrap {
  max-width: 1000px;
  padding: 0 30px;
  margin: 0 auto;
  zoom: 1; }

section {
  @include outer-container; }

article .half {
  @include span-columns(6); }

.list-multicolumn {
  @include column-count(2); }

#map {
  border: 1px solid $clouds; }

.style-none {
  list-style-type: none;
  padding-left: 0;
  margin-left: 0; }

.home-flyer {
  @include span-columns(12);
  @include media($large-screen) {
    @include span-columns(6); }
  img {
    max-width: 340px;
    width: 100%;
    box-shadow: 1px 1px 5px #333; } }
.home-tapas {
  @include span-columns(12);
  @include media($large-screen) {
    @include span-columns(6); } }
.home-events {
  @include span-columns(12);
  @include media($large-screen) {
    @include span-columns(6); } }
.home-facebook {
  margin-top: 2em;
  margin-bottom: 1em;
  @include span-columns(12);
  @include media($large-screen) {
    @include span-columns(6); } }

.container {
  @include outer-container; }

.event {
  @include outer-container;
  background-color: $gray;
  margin-bottom: 1em;
  .event-image {
    height: 100px;
    width: 100px;
    background-size: cover;
    @include span-columns(3); }
  .event-text {
    @include span-columns(9); } }


.gallery-image {
  display: inline;
  width: 25%;
  padding: 0 5px;
  aspect-ratio: 1/1;
  object-fit: cover; }

a {
  color: $red;
  text-decoration: none; }

a.no-decoration {
  color: black;
  text-decoration: none;
  &:hover, &:visited {
    color: black; } }

.page-footer {
  $base-spacing: 1.5em !default;
  $action-color: #477DCA !default;
  $footer-background: $red;
  $footer-color: white;
  $footer-link-color: transparentize($footer-color, 0.6);
  $footer-disclaimer-color: transparentize($footer-color, 0.6);

  background: $footer-background;
  padding: $base-spacing;
  width: 100%;
  display: flex;
  .footer-primary-links {
    flex: 1; }
  ul {
    flex: 0 0 auto;
    line-height: 1.5em;
    margin: 0 0 1em 0;
    padding: 0;
    @include media($large-screen) {
      float: left;
      line-height: 1.8em;
      margin-left: 1em;
      margin-bottom: 0; } }
  ul li {
    list-style: none;
    padding-right: 1em;
    @include media($large-screen) {
      display: inline;
      text-align: left; } }
  ul li a {
    color: $footer-color;
    text-decoration: none;
    &:focus, &:hover {
      color: transparentize($footer-color, 0.5); } }
  .footer-secondary-links {
    flex: 0 0 auto;
    li {
      font-size: 0.8em; }
    ul.footer-social {
      margin: 1em 0 0 0;
      @include media($large-screen) {
        float: right;
        margin-top: 0; }
      li {
        font-size: 1em;
        float: left;
        line-height: 0;
        margin: 0;
        padding-right: 0.7em;
        &:last-child {
          padding-right: 0; } }
      img {
        height: 1.6em;
        opacity: 1.0;
        padding: 1px;
        &:focus,
        &:hover {
          opacity: 0.7; } } } } }

.image-full {
  padding-right: 1em;
  max-width: 100%;
  margin-bottom: 1em;
  img {
    height: auto;
    width: 100%; } }

.image-half {
  padding-right: 1em;
  max-width: 100%;
  margin-bottom: 1em;
  display: inline;
  img {
    height: auto;
    width: 46%; } }

.image-left {
  padding-right: 1em;
  float: left !important;
  max-width: 30%;
  img {
    height: auto;
    width: 100%; } }

.image-right {
  padding-left: 1em;
  float: right !important;
  max-width: 30%;
  img {
    height: auto;
    width: 100%; } }

figure {
  figcaption {
    text-align: center;
    font-size: 0.8em; } }

.slideshow {
  .slide {
    padding-bottom: 0.6em; }
  .thumbnail-wrapper {
    width: 33%;
    padding: 0.3em;
    display: inline-block; }
  .thumbnail {
    width: 100%;
    opacity: 0.9;
    &:hover, &--selected {
      opacity: 0.4; } } }

.small-text {
  font-size: 0.9em; }

.card2 {
  $base-border-color: gainsboro !default;
  $base-spacing: 1.5em !default;
  $card-border-color: $base-border-color;
  $card-border: 1px solid $card-border-color;

  width: 100%;
  @include display(flex);

  position: relative;
  border-bottom: $card-border;
  padding-bottom: 1em;
  padding-top: 1em;

  &:last-child {
    border-bottom: none; }

  .card-image {
    overflow: hidden;
    flex: 20% 0 0;
    height: 100%;

    img {
      border-radius: 100%;
      width: 100%; } }

  .card-body {
    font-size: 0.9em;
    line-height: 1.5em;
    padding: ($base-spacing / 2) ($base-spacing / 2);
    margin-left: 1em;
    h2 {
      margin-top: 0; }

    ul {
      margin: 0; }

    @include media ($large-screen) {
      .recipes {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2; } } } }

.home-info {
  border: 4px solid $red;
  border-radius: 10px;
  padding: 0.5em;
  .home-info--bold {
    font-size: 1.3em;
    font-weight: bold; }
  .home-info--rest {
    font-size: 1.1em; } }

.corona > a {
  background: red;
  color: white;
  padding: 0.5em 1.0em;
  text-decoration: underline;
  font-weight: bold;
  font-size: 2em;
  &:hover {
    text-decoration: none; } }

.image-grid2 {
  width: 100%;
  margin-bottom: 1em;
  img {
    height: 400px;
    object-fit: cover;
    width: 49%; } }
