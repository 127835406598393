// Green
$turquoise:     #1ABC9C;
$green-sea:     #16A085;
$emerald:       #2ECC71;
$nephritis:     #27AE60;

// Blue & Violett
$peter-river:   #3498DB;
$belize-hole:   #2980B9;
$amethyst:      #9B59B6;
$wisteria:      #8E44AD;

// Yellow, Orange & Red
$sun-flower:    #F1C40F;
$orange:        #F39C12;
$carrot:        #E67E22;
$pumpkin:       #D35400;
$alizarin:      #E74C3C;
$pomegranate:   #C0392B;

// Gray & White
$clouds:        #ECF0F1;
$silver:        #BDC3C7;
$concrete:      #95A5A6;
$asbestos:      #7F8C8D;

// Black
$wet-asphalt:   #34495E;
$midnight-blue: #2C3E50;
