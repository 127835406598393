.accordion-tabs-minimal {
  $base-border-color: gainsboro !default;
  $base-background-color: white !default;
  $base-spacing: 1.5em !default;
  $action-color: $red !default;
  $dark-gray: #333 !default;
  $medium-screen: em(640) !default;
  $tab-link-color: $dark-gray;
  $tab-border: 1px solid $base-border-color;
  $tab-active-background: $base-background-color;
  $tab-inactive-color: $base-background-color;
  $tab-inactive-hover-color: silver;
  $tab-mode: $medium-screen;

  @include clearfix;
  line-height: 1.5;
  padding: 0;

  li.tab-header-and-content {
    list-style: none;

    @include media($tab-mode) {
      display: inline; } }

  a.tab-link {
    background-color: $tab-inactive-color;
    border-top: $tab-border;
    color: $tab-link-color;
    display: block;
    padding: ($base-spacing / 2) $gutter;
    text-decoration: none;

    @include media($tab-mode) {
      display: inline-block;
      border-top: 0; }

    &:hover {
      color: $action-color; }

    &:focus {
      outline: none; }

    &.is-active {
      border-bottom: 0;

      @include media($tab-mode) {
        border: $tab-border;
        border-bottom-color: $tab-active-background;
        margin-bottom: -1px; } } }

  .tab-content {
    display: none;
    padding: $base-spacing $gutter;
    width: 100%;

    @include media($tab-mode) {
      border-top: $tab-border;
      float: left; } } }
